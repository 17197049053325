import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de 2 a 6 cm de diàmetre de color carnós a terrós rogenc, llis, sense pèls i als vells pot ser esquamós. Al principi és convex i un poc umbilicat. Les làmines són gruixudes, separades, adnades o un poc decurrents, de color rosa carn primer i després blanc. Les espores són lila fluix en massa, berrugoses, rodones, de 8,5 x 11 micres i el peu és esvelt, fibrós, un poc pelut a la base i de color semblant al capell però més apagat.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      